<template>
  <nuxt-page />
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

import { showAlert } from '@/composables/useDialog';
import { BRAND_PAGE_URL, HOME_PAGE_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { useUserStore } from '@/stores/user.store';
import { redirectTo } from '@/utils/common.util';
import { definePageMeta } from '#imports';

definePageMeta({
  middleware: [
    'check-login-middleware',
    'check-member-register-middleware',
    'check-user-age-middleware',
    'check-agree-term-middleware',
    'check-invalid-group-middleware',
    'check-menu-readable-middleware'
  ]
});

const userStore = useUserStore();
const { t } = useI18n();

const { selectedGroupId, joinedGroups, selectedGroupInfo } = storeToRefs(userStore);

const checkInprogressMigration = async () => {
  const groupInfo = selectedGroupInfo.value;
  if (!groupInfo) { return; }
  const { onMigrationYn = Confirmation.NO, onProjectMigrationYn = Confirmation.NO } = groupInfo;
  if (onMigrationYn === Confirmation.NO && onProjectMigrationYn === Confirmation.NO) {
    return;
  }
  await showAlert({
    content: t('studio.transfer_in_progress.all_features_unavailable_msg'),
    panelClasses: 'w-[39.2rem]'
  });
  for (const group of joinedGroups.value ?? []) {
    if (group.groupId === selectedGroupId.value || group.onMigrationYn === Confirmation.YES || group.onProjectMigrationYn === Confirmation.YES) {
      continue;
    }
    return await redirectTo(HOME_PAGE_URL, { external: true, groupId: group.groupId });
  }
  await redirectTo(BRAND_PAGE_URL, { external: true });
};

const init = async () => {
  checkInprogressMigration();
};

init();

</script>
